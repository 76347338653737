html {
	height: 100%;
}

body {
    background-color: #FFFFFF;
    height: 100%;
}

/** BEGIN Parent Container **/

#parent-container {
	min-height: 100%;
	padding-bottom: 86px;
	margin-bottom: -86px;
}

/** END Parent Container **/

/** BEGIN Main Navigation **/

#main-nav {
	margin-bottom: 25px;
	background-color: #754B8E;
}

/** END Main Navigation **/

/** BEGIN Main Footer **/

#main-footer {
	margin-top: 30px;
	height: 56px;
	background-color: black;
	color: white;
}
	#main-footer a {
		color: white;
	}

/** END Main Footer **/

/** BEGIN HR Text **/

.hr-text {
	position: relative;
	text-align: center;
}

.hr-text span {
	padding: 0 15px;
	position: relative;
	z-index: 1;
}

.hr-text:before {
	background: #ddd;
	content: "";
	display: block;
	height: 1px;
	position: absolute;
	top: 50%;
	width: 100%;
}

.hr-text:before {
	left: 0;
}

/** END HR Text **/

/** BEGIN select2 edits **/

.select2-results__options {
	max-height: 200px;
	overflow-y: auto;
}

.select2-container {
	background-color: white;
	color: black;
	display: block;
}

/** END select2 edits **/